import React, { createContext, useState, useEffect } from 'react';
import { app } from './resources/firebase';
import { getAnalytics } from "firebase/analytics";

export const myContext = createContext();

const ContextProvider = ({ children }) => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const analytics = getAnalytics(app);

    const idioma = navigator.language;

    const [Language, setLanguage] = useState("en");

    useEffect(() => {
        if (idioma.startsWith("pt")) {
            setLanguage("pt");
        } else if (idioma.startsWith("en")) {
            setLanguage("en");
        } else if (idioma.startsWith("de")) {
            setLanguage("de");
        } else {
            setLanguage("en");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idioma]);

    const [date, setDate] = useState(new Date());
    const [DataFormatada, setDataFormatada] = useState(null);
    const [Name, setName] = useState("");
    const [Origem, setOrigem] = useState(null);
    const [OrigemCarm, setOrigemCarm] = useState(0);
    const [Destino, setDestino] = useState(null);
    const [DestinoCarm, setDestinoCarm] = useState(0);
    const [Alma, setAlma] = useState(null);
    const [Personalidade, setPersonalidade] = useState(null);
    const [PersonalidadeCarm, setPersonalidadeCarm] = useState(null);
    const [Nascimento, setNascimento] = useState(null);
    const [NumeroPoderoso, setNumeroPoderoso] = useState(null);
    const [NumeroPoderosoCarm, setNumeroPoderosoCarm] = useState(null);
    const [Pinaculos, setPinaculos] = useState({});
    const [PinaculoVigente, setPinaculoVigente] = useState(null);
    const [Carma, setCarma] = useState({ numerosAusentes: 0, numerosEmExcesso: 0 });
    const [Desafios, setDesafios] = useState({});
    const [AnoPessoal, setAnoPessoal] = useState(null);
    const [MesesPessoal, setMesesPessoal] = useState(null);
    const [DiasPessoal, setDiasPessoal] = useState(null);
    const [ListaNomes, setListaNomes] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        if (Destino !== null && Origem !== null) {
            // console.log("Destino: ", Destino);
            // console.log("Origem: ", Origem);
            const numeroPoderosoReduzido = reduzirNumeroExceto(Destino + Origem);
            setNumeroPoderoso(numeroPoderosoReduzido);
        }
        // console.log("Pinaculos: ",Pinaculos);
        // console.log("Carma: ",Carma);
        // console.log("Origem Carm: ",OrigemCarm);
        // console.log("Destino Carm: ",DestinoCarm);
        // console.log("PersonalidadeCarm: ",PersonalidadeCarm);
    }, [Destino, Origem]);

    // Atualiza Origem, OrigemCarm e Nascimento
    // useEffect(() => {
    //   if (date !== null) {
    //     calcularOrigem(date);
    //   }
    // }, [date]);

    // Atualiza Destino e DestinoCarm
    useEffect(() => {
        if (Name !== "") {
            calcularNumeroDestino(Name);
        }
    }, [Name]);

    // Atualiza Personalidade e PersonalidadeCarm
    useEffect(() => {
        if (Name !== "") {
            calcularPersonalidade(Name);
        }
    }, [Name]);

    // Atualiza Carma
    useEffect(() => {
        if (Name !== "") {
            calcularCarma(Name);
        }
    }, [Name]);

    // carrega lista de nomes ja consultados
    useEffect(() => {
        const carregarLista = async () => {
            const lista = await loadListaNomes();
            setListaNomes(lista);
        }
        carregarLista();
        // console.log("**************   ContextProvider.js useEffect listaNome: ", ListaNomes);
    }, []);

    // Função para reduzir um número a um dígito
    const reduzirNumero = (numero) => {
        let soma = 0;
        while (numero > 0 || soma > 9) {
            if (numero === 0) {
                numero = soma;
                soma = 0;
            }
            soma += numero % 10;
            numero = Math.floor(numero / 10);
        }
        return soma;
    }

    // nao reduz os numeros poderosos
    // const reduzirNumeroExceto = (numero) => {
    //     // Exceções para números 11 e 22
    //     if (numero === 11 || numero === 22) {
    //         return numero;
    //     } else {
    //         let soma = 0;
    //         const digitos = numero.toString().split('').map(digito => parseInt(digito));
    //         digitos.forEach(digito => soma += digito);
    //         if (soma === 11 || soma === 22) {
    //             return soma;
    //         } else {
    //             let novaSoma = 0;
    //             while (soma > 0) {
    //                 novaSoma += soma % 10;
    //                 soma = Math.floor(soma / 10);
    //             }
    //             return novaSoma;
    //         }
    //     }
    // };

    const reduzirNumeroExceto = (numero) => {
        // Exceções para números 11 e 22
        if (numero === 11 || numero === 22) {
            return numero;
        } else {
            let soma = numero.toString().split('').reduce((acumulador, digito) => acumulador + parseInt(digito), 0);
    
            if (soma === 11 || soma === 22) {
                return soma;
            }
    
            // Faz uma segunda soma se a primeira soma for um número de dois dígitos que não seja 11 ou 22
            while (soma > 9) {
                soma = soma.toString().split('').reduce((acumulador, digito) => acumulador + parseInt(digito), 0);
            }
            return soma;
        }
    };

    // *****************                 Calculo do numero de origem
    const calcularOrigem = async (data) => {

        // Separar a data em ano, dia e mês
        let [dia, mes, ano] = await data.split("-");

        const reduzDia = reduzirNumeroExceto(parseInt(dia));
        const reduzMes = reduzirNumeroExceto(parseInt(mes));
        const reduzAno = reduzirNumeroExceto(parseInt(ano));
        // console.log("Dia: ", reduzDia, "mes: ", reduzMes, "Ano: ", reduzAno);

        //**************                Dia Nascimento
        setNascimento(dia);

        // *****************            Origem
        const reduzOrigin = await reduzirNumeroExceto(reduzDia + reduzAno + reduzMes);
        // console.log("Reduz Origon", reduzOrigin);
        setOrigem(reduzOrigin);

        // *************                Origem Carmico
        let numeros = [];

        numeros.push(reduzDia);
        numeros.push(reduzMes);
        numeros.push(reduzAno);

        let somaTotal = await numeros.reduce((total, num) => total + num, 0);

        // Reduzir a soma total a um dígito (exceto se for 13, 14, 16, 19)
        if (![13, 14, 16, 19].includes(somaTotal)) {
            // somaTotal = reduzirNumero(somaTotal);
            somaTotal = 0
        }
        console.log("Origem KARM",somaTotal);
        setOrigemCarm(somaTotal);

        // *************                  Calculo dos pinaculos
        const primeiro = await reduzirNumeroExceto(reduzDia + reduzMes);
        const segundo = await reduzirNumeroExceto(reduzDia + reduzAno);
        const terceiro = await reduzirNumeroExceto(primeiro + segundo);
        const quarto = await reduzirNumeroExceto(reduzMes + reduzAno);

        // CALCULA IDADE ENTRE PINACULOS
        function calcularIdade(dataAniversario) {
            setDataFormatada(dataAniversario);

            const partesData = dataAniversario.split("-");
            const dia = parseInt(partesData[0], 10);
            const mes = parseInt(partesData[1], 10) - 1;
            const ano = parseInt(partesData[2], 10);
            const dataNascimento = new Date(ano, mes, dia);
            const dataAtual = new Date();

            let idade = dataAtual.getFullYear() - dataNascimento.getFullYear();
            const mesAniversario = dataNascimento.getMonth();
            if (mesAniversario > dataAtual.getMonth() || (mesAniversario === dataAtual.getMonth() && dataNascimento.getDate() > dataAtual.getDate())) {
                idade--;
            }
            // console.log("Idade: ", idade);
            // 36 -origem
            const idadePrimeiroPinaculo = 36 - reduzOrigin;
            const idadeSegundoPinaculoInicio = idadePrimeiroPinaculo + 1;
            const idadeSegundoPinaculoFim = idadeSegundoPinaculoInicio + 9
            const idadeTerceiroPinaculoInicio = idadeSegundoPinaculoFim + 1;
            const idadeTerceiroPinaculoFim = idadeTerceiroPinaculoInicio + 9
            const idadeQuartoPinaculoInicio = idadeTerceiroPinaculoFim + 1;

            setPinaculos((estadoAnterior) => ({
                ...estadoAnterior,
                Primeiro: {
                    numero: primeiro,
                    idade: idadePrimeiroPinaculo
                },
                Segundo: {
                    numero: segundo,
                    idadeInicio: idadeSegundoPinaculoInicio,
                    idadeFim: idadeSegundoPinaculoFim
                },
                Terceiro: {
                    numero: terceiro,
                    idadeInicio: idadeTerceiroPinaculoInicio,
                    idadeFim: idadeTerceiroPinaculoFim
                },
                Quarto: {
                    numero: quarto,
                    idadeInicio: idadeQuartoPinaculoInicio,
                }
            }));

            let pinaculoVigente = '';

            if (idade <= idadePrimeiroPinaculo && idade < idadeSegundoPinaculoInicio) {
                pinaculoVigente = primeiro;
            } else if (idade >= idadeSegundoPinaculoInicio && idade <= idadeSegundoPinaculoFim) {
                pinaculoVigente = segundo;
            } else if (idade >= idadeTerceiroPinaculoInicio && idade <= idadeTerceiroPinaculoFim) {
                pinaculoVigente = terceiro;
            } else if (idade >= idadeQuartoPinaculoInicio) {
                pinaculoVigente = quarto;
            }
            setPinaculoVigente(pinaculoVigente);

        }
        calcularIdade(data);

        // **********************                     Calculo dos Desafios
        function reduzirNumeroDesafio(num1, num2) {
            // checa qual número é maior
            const maior = num1 >= num2 ? num1 : num2;
            const menor = num1 < num2 ? num1 : num2;

            // subtrai o menor do maior
            let resultado = maior - menor;

            // checa se o resultado é negativo
            if (resultado < 0) {
                resultado += 9; // adiciona 9 para garantir que o resultado seja positivo
            }

            return resultado;
        }

        const primeiroDesafio = await reduzirNumeroDesafio(reduzDia, reduzMes);
        const segundoDesafio = await reduzirNumeroDesafio(reduzDia, reduzAno);
        const terceiroDesafio = await reduzirNumeroDesafio(primeiroDesafio, segundoDesafio);
        const quartoDesafio = await reduzirNumeroDesafio(reduzMes, reduzAno);

        setDesafios((estadoAnterior) => ({
            ...estadoAnterior,
            primeiro: primeiroDesafio,
            segundo: segundoDesafio,
            terceiro: terceiroDesafio,
            quarto: quartoDesafio
        }));

        // ********************                  Ano Pessoal
        function calcularAnoPessoal(dia, mes) {
            const dataAtual = new Date();
            const ano = dataAtual.getFullYear();
            const somaDigitos = (num) => {
                let soma = 0;
                while (num > 0) {
                    soma += num % 10;
                    num = Math.floor(num / 10);
                }
                return soma;
            }
            const reduzirParaUnicoDigito = (num) => {
                while (num > 9) {
                    num = somaDigitos(num);
                }
                return num;
            }
            const somaDiaMes = reduzirParaUnicoDigito(dia + mes);
            const somaAno = reduzirParaUnicoDigito(ano);
            const resultado = reduzirParaUnicoDigito(somaDiaMes + somaAno);
            return resultado;
        }
        const anoPessoal = calcularAnoPessoal(reduzDia, reduzMes);

        setAnoPessoal(anoPessoal);

        // ********************                   to calculate the personal days of the month
        const datasPessoais = async () => {

            // Calcular o mêses do ano pessoal
            let mesesPessoais = {};
            for (let i = 1; i <= 12; i++) {
                mesesPessoais[i] = reduzirNumeroExceto(anoPessoal + i);
            }

            // Calcular os dias do mes pessoal
            const dataAtu = new Date();
            let diasPessoais = {};
            const mesAtual = dataAtu.getMonth() + 1;
            const diasNoMes = new Date(dataAtu.getFullYear(), mesAtual, 0).getDate();

            for (let i = 1; i <= diasNoMes; i++) {
                diasPessoais[i] = reduzirNumeroExceto(mesesPessoais[mesAtual] + i);
            }

            // Retornar os resultados
            setMesesPessoal(mesesPessoais);
            setDiasPessoal(diasPessoais);
            // console.log(mesesPessoais);
            // console.log(diasPessoais);
        }
        datasPessoais();
    }


    const calcularNumeroDestino = async (nomeCompleto) => {
        // console.log("******** CalcularNumeroDestino Params Received: ", nomeCompleto );
        // console.log(nomeCompleto);
        // Tabela numerológica de vogais e consoantes
        const tabelaNumerologica = {
            'A': 1, 'E': 5, 'I': 9, 'O': 6, 'U': 3,
            'B': 2, 'C': 3, 'D': 4, 'F': 6, 'G': 7, 'H': 8, 'J': 1, 'K': 2, 'L': 3, 'M': 4, 'N': 5,
            'P': 7, 'Q': 8, 'R': 9, 'S': 1, 'T': 2, 'V': 4, 'W': 5, 'X': 6, 'Y': 7, 'Z': 8, "SS": 1
        };

        // Função para calcular o valor numerológico de um nome
        const calcularValor = (nome, tabela) => {
            let valor = 0;
            // console.log(valor)
            for (const letra of nome) {
                const letraMaiuscula = letra.toUpperCase();
                if (tabela[letraMaiuscula]) {
                    valor += tabela[letraMaiuscula];
                    // console.log(valor)
                }
                // console.log(letra)
            }
            if(valor === 11 || valor === 22){
                return valor
            }else{
                return reduzirNumeroExceto(valor);
            }
        }

        // Calcular o valor numerológico de cada nome separadamente
        const nomes = await nomeCompleto.split(' ');
        const valores = await nomes.map((nome) => calcularValor(nome, tabelaNumerologica));

        // Soma dos valores sem redução de números mestres e exceções
        const soma = await valores.reduce((acc, valor) => acc + valor, 0);
        const excecoes = [13, 14, 16, 19, 11, 22];
        if (excecoes.includes(soma)) {
            setDestinoCarm(soma);
            setDestino(reduzirNumeroExceto(soma));
            return soma;
        } else {
            setDestino(reduzirNumeroExceto(soma));
            return reduzirNumeroExceto(soma);
        }
    }

    // Exigencia da Alma
    const calcularNumeroVogais = async (nomeCompleto) => {
        // console.log("calcularNumeroVogais: ",nomeCompleto);
        // Tabela numerológica de vogais
        const tabelaNumerologica = {
            'A': 1, 'E': 5, 'I': 9, 'O': 6, 'U': 3
        };

        // Função para calcular o valor numerológico de um nome
        function calcularValor(nome, tabela) {
            let valor = 0;
            for (const letra of nome) {
                const letraMaiuscula = letra.toUpperCase();
                if (tabela[letraMaiuscula]) {
                    valor += tabela[letraMaiuscula];
                }
            }
            return valor;
        }

        // Calcular o valor numerológico de cada nome separadamente
        const nomes = nomeCompleto.split(' ');
        const valores = await nomes.map((nome) => calcularValor(nome, tabelaNumerologica));

        // Soma dos valores sem redução de números mestres e exceções
        const excecoes = [13, 14, 16, 19, 11, 22];
        let soma = 0;
        for (const valor of valores) {
            if (excecoes.includes(valor)) {
                soma += valor;
            } else {
                soma += [...valor.toString()].reduce((acc, digito) => acc + Number(digito), 0);
            }
        }

        // Redução do resultado final
        if (excecoes.includes(soma)) {
            setAlma(soma);
            setAlma(reduzirNumeroExceto(soma))
            return soma;
        } else {
            setAlma(reduzirNumeroExceto(soma))
            return [...soma.toString()].reduce((acc, digito) => acc + Number(digito), 0);
        }
    }

    function calcularPersonalidade(nomeCompleto) {
        // Tabela numerológica de consoantes
        const tabelaNumerologicaConsoantes = {
            'B': 2, 'C': 3, 'D': 4, 'F': 6, 'G': 7, 'H': 8, 'J': 1, 'K': 2, 'L': 3, 'M': 4, 'N': 5,
            'P': 7, 'Q': 8, 'R': 9, 'S': 1, 'T': 2, 'V': 4, 'W': 5, 'X': 6, 'Y': 7, 'Z': 8, "SS": 1
        };

        // Função para calcular o valor numerológico de um nome
        function calcularValor(nome, tabela) {
            let valor = 0;
            for (const letra of nome) {
                const letraMaiuscula = letra.toUpperCase();
                if (tabela[letraMaiuscula]) {
                    valor += tabela[letraMaiuscula];
                }
            }
            return valor;
        }

        // Calcular o valor numerológico de cada nome separadamente
        const nomes = nomeCompleto.split(' ');
        const valores = nomes.map((nome) => calcularValor(nome, tabelaNumerologicaConsoantes));

        // Soma dos valores e redução de números mestres e exceções
        let soma = valores.reduce((acc, valor) => acc + valor, 0);
        const excecoes = [13, 14, 16, 19, 11, 22];

        if (excecoes.includes(soma)) {
            setPersonalidadeCarm(soma);
            setPersonalidade(reduzirNumeroExceto(soma));

            return soma;
        } else {
            while (soma > 9) {
                soma = [...soma.toString()].reduce((acc, digito) => acc + Number(digito), 0);
            }

            setPersonalidade(soma);

            return soma;
        }
    }

    function calcularCarma(nome) {
        const letrasParaNumeros = {
            A: 1, B: 2, C: 3, D: 4, E: 5, F: 6, G: 7, H: 8, I: 9,
            J: 1, K: 2, L: 3, M: 4, N: 5, O: 6, P: 7, Q: 8, R: 9,
            S: 1, T: 2, U: 3, V: 4, W: 5, X: 6, Y: 7, Z: 8, SS: 1
        };

        const frequenciaNumeros = new Array(9).fill(0);
        const nomeSemEspacos = nome.replace(/\s+/g, '').toUpperCase();

        for (const letra of nomeSemEspacos) {
            if (letrasParaNumeros.hasOwnProperty(letra)) {
                const numero = letrasParaNumeros[letra];
                frequenciaNumeros[numero - 1]++;
            }
        }

        const numerosAusentes = [];
        let maiorFrequencia = 1;
        let numerosEmExcesso = [];

        for (let i = 0; i < frequenciaNumeros.length; i++) {
            if (frequenciaNumeros[i] === 0) {
                numerosAusentes.push(i + 1);
            } else if (frequenciaNumeros[i] > maiorFrequencia) {
                maiorFrequencia = frequenciaNumeros[i];
                numerosEmExcesso = [i + 1];
            } else if (frequenciaNumeros[i] === maiorFrequencia) {
                numerosEmExcesso.push(i + 1);
            }
        }

        setCarma((estadoAnterior) => ({
            ...estadoAnterior,
            numerosAusentes: numerosAusentes,
            numerosEmExcesso: numerosEmExcesso
        }));

        return {
            numerosAusentes,
            numerosEmExcesso
        };
    }

    // carrega lista de nomes consultados
    const loadListaNomes = async () => {
        try {
            const jsonLista = await localStorage.getItem('@listaNomes');
            return jsonLista != null ? JSON.parse(jsonLista) : [];
        } catch (e) {
            // console.log('Erro ao carregar lista de nomes: ', e);
            return [];
        }
    }

    return (
        <myContext.Provider
            value={{
                analytics,
                Language,
                setLanguage,
                error, setError,
                date, setDate,
                DataFormatada, setDataFormatada,
                Name, setName,
                Origem, setOrigem,
                OrigemCarm, setOrigemCarm,
                Destino, setDestino,
                DestinoCarm, setDestinoCarm,
                Alma, setAlma,
                Personalidade, setPersonalidade,
                PersonalidadeCarm, setPersonalidadeCarm,
                Nascimento, setNascimento,
                NumeroPoderoso, setNumeroPoderoso,
                NumeroPoderosoCarm, setNumeroPoderosoCarm,
                Pinaculos, setPinaculos,
                PinaculoVigente, setPinaculoVigente,
                Carma, setCarma,
                Desafios, setDesafios,
                AnoPessoal, setAnoPessoal,
                MesesPessoal, setMesesPessoal,
                DiasPessoal, setDiasPessoal,
                ListaNomes, setListaNomes,
                calcularOrigem,
                calcularNumeroDestino,
                calcularPersonalidade,
                calcularCarma,
                calcularNumeroVogais,
            }}
        >
            {children}
        </myContext.Provider>
    );
};

export default ContextProvider;

import React from 'react'

export default function PolicyDE() {
    return (
        <div className='policy-container'>
            <div className='policy-text-section'>

                <h1>Datenschutzerklärung</h1>

                <span>Allgemeine Informationen</span>
                <p>Diese Numerologie-App wird von A&T Comercial angeboten. Wir sind für die Verarbeitung von personenbezogenen Daten verantwortlich, die beim Gebrauch dieser App anfallen. Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und halten uns strikt an die geltenden Datenschutzgesetze.

                </p>
                <br />
                <span>Sammlung persönlicher Daten </span>
                <p>Diese App sammelt keine persönlichen Daten. Wir erfassen, speichern oder verwenden keine Informationen, die zur Identifizierung Ihrer Person verwendet werden können. Wir erfassen auch keine Informationen über die Nutzung der App oder andere technische Informationen, außer für statistische Zwecke mit Google Analytics.

                </p>
                <br />
                <span>Speicherdauer
                </span>
                <p>Die von Google Analytics erfassten Daten werden gemäß den Aufbewahrungsrichtlinien von Google Analytics gespeichert. Diese Richtlinien sehen vor, dass die Daten für einen Zeitraum von maximal 26 Monaten aufbewahrt werden, nach dem sie automatisch von den Google Analytics-Servern gelöscht werden. Dies bedeutet, dass die von Google Analytics über unsere App erfassten Daten nicht unbegrenzt aufbewahrt werden und nach Ablauf von 26 Monaten gelöscht werden. Es ist jedoch zu beachten, dass Google Analytics in einigen Fällen die Daten für längere Zeit aufbewahren kann, um gesetzlichen Verpflichtungen, regulatorischen Anforderungen oder Sicherheitszwecken des Systems gerecht zu werden.
                    <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>
                <br />
                <span>Google Analytics </span>
                <p>Diese App verwendet Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Google Analytics verwendet "Cookies", Textdateien, die auf Ihrem Gerät gespeichert werden und die eine Analyse der Benutzung der App ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung der App (einschließlich Ihrer IP-Adresse) werden an Google übertragen und auf Servern in den USA gespeichert. Google wird diese Informationen verwenden, um die Nutzung der App auszuwerten, Berichte über die App-Aktivitäten für uns zu erstellen und weitere mit der App-Aktivität und der Internetnutzung verbundene Dienstleistungen zu erbringen. Google kann diese Informationen gegebenenfalls auch an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird Ihre IP-Adresse nicht mit anderen Daten von Google in Verbindung bringen. Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser App vollumfänglich nutzen können. Durch die Nutzung dieser App erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden. Verarbeitete personenbezogene Daten: Nutzungsdaten; Tracker. Datenschutzerklärung - Opt Out.
                    Hier finden Sie weitere Informationen zur Datennutzung durch Google Inc.:

                    <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a> (Daten, die von Google-Partnern erhoben werden)   <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> (Einstellungen über die angezeigte Werbung) <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a> (Verwendung von Cookies in Anzeigen)</p>
                <br />
                <span>Verkauf der Vollversion und Verlinkung zum Buch auf Amazon</span>
                <p>Diese App bietet den Verkauf der kostenpflichtigen Vollversion über den Google Play Store an und verfügt über einen Weiterleitungslink zum Amazon-Shop für den Kauf des mit der App verbundenen Numerologie-Buchs. Während dieser Transaktionen werden keine persönlichen Daten erfasst, da diese direkt über Google Play, Apple App Store oder Amazon abgewickelt werden und den Datenschutzrichtlinien dieser Dienste unterliegen.


                    Google Play: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                    App Store: <a href="https://www.apple.com/legal/privacy/">https://www.apple.com/legal/privacy/</a>
                    Amazon: <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496">https://www.amazon.com/gp/help/customer/display.html?nodeId=468496</a></p>
                <br />

                <span>Zahlungsmittel</span>
                <p>
                    <span className='inner-text'>Google Play Store:</span> Zahlungen im Google Play Store werden von Google verarbeitet und unterliegen der Zahlungsrichtlinie von Google. Google erfasst und verarbeitet Ihre Zahlungsinformationen wie Kreditkartennummer, Rechnungsadresse und Abrechnungsinformationen in Übereinstimmung mit den geltenden Datenschutzgesetzen. Weitere Informationen zur Verarbeitung von Zahlungsinformationen durch Google finden Sie in der Google-Zahlungsrichtlinie.
                    <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.<br />
                    <span className='inner-text'>Apple App Store:</span> Zahlungen im Apple App Store werden von Apple verarbeitet und unterliegen den Geschäftsbedingungen des App Store. Apple erfasst und verarbeitet Ihre Zahlungsinformationen wie Kreditkartennummer, Rechnungsadresse und Abrechnungsinformationen in Übereinstimmung mit den geltenden Datenschutzgesetzen. Weitere Informationen zur Verarbeitung von Zahlungsinformationen durch Apple finden Sie in der Apple-Datenschutzrichtlinie.  <a href="https://www.apple.com/legal/privacy/">https://www.apple.com/legal/privacy/</a>. <br />
                    <span className='inner-text'>Amazon:</span> Zahlungen bei Amazon werden von Amazon verarbeitet und unterliegen den Geschäftsbedingungen von Amazon Payments. Amazon erfasst und verarbeitet Ihre Zahlungsinformationen wie Kreditkartennummer, Rechnungsadresse und Abrechnungsinformationen in Übereinstimmung mit den geltenden Datenschutzgesetzen. Weitere Informationen zur Verarbeitung von Zahlungsinformationen durch Amazon finden Sie in der Amazon Payments-Datenschutzrichtlinie. <a href="https://pay.amazon.com/us/help/201751600">https://pay.amazon.com/us/help/201751600</a>.</p>
                <br />

                <span>Ihre Rechte</span>
                <p>Sie haben das Recht zu wissen, welche personenbezogenen Daten erfasst wurden, wie sie verwendet werden und wie lange sie gespeichert werden. Sie haben das Recht, Zugang, Berichtigung, Löschung oder Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Sie haben auch das Recht, eine Beschwerde bei der zuständigen Datenschutzbehörde einzureichen.
                </p>
                <br />
                <span>Kontakt</span>
                <p>Wenn Sie Fragen oder Bedenken haben, wie Ihre personenbezogenen Daten verarbeitet werden, oder wenn Sie Ihre Datenschutzrechte ausüben möchten, kontaktieren Sie uns bitte über die folgende E-Mail-Adresse: at.comercial.business@gmail.com. Wenn Sie über die angebotenen Kontaktmöglichkeiten mit uns in Verbindung treten, werden Ihre Daten gespeichert, damit sie zur Bearbeitung und Beantwortung Ihrer Anfrage verwendet werden können. Nach der Bearbeitung und Beantwortung Ihrer Anfrage wird Ihre E-Mail-Adresse gelöscht, um den Schutz Ihrer personenbezogenen Daten zu gewährleisten. Keine Ihrer Daten werden ohne Ihre Zustimmung an Dritte weitergegeben.


                </p>
                <br />
                <span>Änderung und Aktualisierung der Datenschutzrichtlinie</span>
                <p>Diese Datenschutzerklärung wird regelmäßig aktualisiert. Die aktuellste Version ist stets in dieser App verfügbar. Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir werden die Datenschutzerklärung anpassen, sobald Änderungen in der von uns durchgeführten Datenverarbeitung dies erforderlich machen. Wir werden Sie informieren, sobald Änderungen Ihre Mitwirkung (z.B. Einwilligung) oder eine andere individuelle Benachrichtigung erfordern. Wenn wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und Organisationen angeben, beachten Sie bitte, dass sich die Adressen im Laufe der Zeit ändern können und wir Sie bitten, die Informationen vor der Kontaktaufnahme mit uns zu überprüfen.
                </p>

            </div>
        </div>
    )
}

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCvtX7zqYrXg2o_rtdj27MImNV4xu93tIE",
    authDomain: "power-of-numerology.firebaseapp.com",
    projectId: "power-of-numerology",
    storageBucket: "power-of-numerology.appspot.com",
    messagingSenderId: "410468703818",
    appId: "1:410468703818:web:9a67861aaf89c4faf490b3",
    measurementId: "G-WD2N9ZFMXF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export {
    app
}
import React, { useContext } from 'react'
import amazon from "../img/amazon-logo.png"
import language from '../locales/language'
import { myContext } from '../context/ContextProvider';
import { Link } from 'react-router-dom';

export default function Step1() {
  const { Language } = useContext(myContext);
  const logo = "https://firebasestorage.googleapis.com/v0/b/image-provider-b2b36.appspot.com/o/aethome%2FA%26T%20TALES.png?alt=media&token=c3fb72a3-b539-4ab8-9818-8a4faab525a3"
  return (
    <div className='step1-section'>
      <p>{language[Language].step1.step} </p>
      <Link to={language[Language].amazon.paperback}>
        <img src={logo} alt="amazon-logo" />
      </Link>
      <div className='book-options'>
        {language[Language].step1.type.map((item, index) => (
          <div key={index}>
            <Link to={language[Language].amazon.paperback}>
              <p>{item}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

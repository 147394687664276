// eslint-disable-next-line import/no-anonymous-default-export
export default {

    origin: {
        title: 'Ursprungspfadzahl',
        description: 'Diese Zahl zeigt deine Mission und Lebenszweck auf. Sie hilft dabei, deine angeborenen Fähigkeiten und Herausforderungen zu verstehen sowie die allgemeine Richtung, die dein Leben nehmen sollte.',
    },
    
    day: {
        title: 'Geburtstagszahl',
        description: 'Dies spiegelt die Qualitäten und Fähigkeiten wider, die wir seit der Geburt mitbringen, und kann verwendet werden, um unsere Talente, Stärken und Herausforderungen zu verstehen.',
    },
    
    destiny: {
        title: 'Schicksalszahl',
        description: 'Repräsentiert dein Potenzial und die Fähigkeiten, die du im Laufe deines Lebens entwickeln solltest.',
    },
    
    heart: {
        title: 'Seelenzahl',
        description: 'Diese Zahl offenbart deine inneren Motivationen und wahren Wünsche. Sie hilft dabei zu verstehen, was deine Seele wirklich nährt und was du auf einer tieferen Ebene erreichen möchtest.',
    },
    
    personality: {
        title: 'Persönlichkeitszahl',
        description: 'Repräsentiert das Bild, das du der Welt präsentierst und wie dich andere sehen. Diese Zahl hilft dabei, zu verstehen, wie du dich authentischer und attraktiver präsentieren kannst.',
    },
    
    powernumber: {
        title: 'Mächtige Zahl',
        description: 'Repräsentiert den Energiekoeffizienten der Person und beeinflusst sowohl den Ursprungspfad als auch das Schicksal erheblich und wirkt als Ausgleich während des Lebens.',
    },
    
    vowel: {
        title: 'Erster Vokal',
        description: 'Der erste Vokal zeigt deine emotionalen Impulse, Einstellungen und wie du reagieren oder reagieren wirst, also wie man erwartet, dass du denkst und handelst.',
    },
    
    pinacle: {
        title: 'Pinnacle',
        description: 'Diese Zyklen repräsentieren verschiedene Phasen unseres Lebens, jede mit ihren eigenen Lektionen, Herausforderungen und Chancen.',
    },
    
    karma: {
        title: 'Karmische Lektionen',
        description: 'Hilft dabei, karmische Muster zu identifizieren und zu verstehen, welche Lektionen wir lernen müssen, um unser Karma auszugleichen und uns spirituell weiterzuentwickeln.',
    },
    
    chalenge: {
        title: 'Herausforderungen',
        description: 'Hindernisse oder Lektionen, die wir im Laufe unseres Lebens bewältigen und überwinden müssen.',
    },
    
    year: {
        title: 'Persönliches Jahr',
        description: 'Die vorherrschende Energie im Jahr.',
    },
    month: {
        title: "Persönlicher Monat",
        description: "Die vorherrschende Energie jedes Monats",
        text: {
            month: "Monat",
            value: "Wert"
        }
    },
    days:{
        title: "Persönliche Tage",
        description: "Die vorherrschende Energie für jeden Tag des aktuellen Monats",
        text: {
            day: "Tag",
            value: "Wert"
        }
    }
    
}
import React from 'react'
import { LiaExclamationSolid } from "react-icons/lia";
import { myContext } from '../../context/ContextProvider';

export default function ModalError({ message }) {
    const { setError } = React.useContext(myContext);

    return (
        <div className='modal-error'>
            <h1><LiaExclamationSolid /></h1>
            <p>{message}</p>
            <button type="button" onClick={() => setError("")} >OK</button>
        </div>
    )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {

    origin: {
        title: 'Origin Path',
        description: 'This number shows your mission and purpose in life. It helps to understand your innate abilities and challenges, as well as the general direction your life should take.',
    },
    
    day: {
        title: 'Birthday',
        description: 'This reflects the qualities and abilities that we bring from birth and can be used to understand our talents, strengths, and challenges.',
    },
    
    destiny: {
        title: 'Destiny',
        description: 'Represents your potential and the skills you should develop throughout your life.',
    },
    
    heart: {
        title: 'Soul Requirement',
        description: 'This number reveals your internal motivations and true desires. It helps to understand what truly nourishes your soul and what you want to achieve on a deeper level.',
    },
    
    personality: {
        title: 'Personality',
        description: 'Represents the image you present to the world and how others see you. This number helps to understand how you can present yourself more authentically and attractively.',
    },
    
    powernumber: {
        title: 'Power Number',
        description: 'Represents the energy coefficient of the person and significantly influences both the origin path and destiny, acting as a balance throughout life.',
    },
    
    vowel: {
        title: 'First Vowel',
        description: 'The first vowel shows your emotional impulses, attitudes, and how you react or will react, that is, how you are expected to think and act.',
    },
    
    pinacle: {
        title: 'Pinnacle',
        description: 'These cycles represent different phases of our lives, each with its own lessons, challenges, and opportunities.',
    },
    
    karma: {
        title: 'Karmic Lessons',
        description: 'Helps to identify karmic patterns and understand which lessons we need to learn to balance our karma and evolve spiritually.',
    },
    
    chalenge: {
        title: 'Challenges',
        description: 'Obstacles or lessons we need to overcome throughout our lives.',
    },
    
    year: {
        title: 'Personal Year',
        description: 'The predominant energy in the year.',
    },
    month: {
        title: "Personal Month",
        description: "The predominant energy of each month",
        text: {
            month: "Month",
            value: "Value"
        }
    },
    days:{
        title: "Personal days",
        description: "The predominant energy for each day of the current month",
        text: {
            day: "Day",
            value: "Value"
        }
    }
}
import React from 'react'

export default function Policy() {
    return (
        <div className='policy-container'>
            <div className='policy-text-section'>

                <h1>Privacy Policy</h1>

                <span>General Information</span>
                <p>This numerology app is provided by A&T Comercial. We are responsible for the processing of personal data that occurs when using this app. We take the protection of your personal data very seriously and strictly follow applicable data protection laws.</p>
                <br />
                <span>Collection of personal data</span>
                <p>This app does not collect personal data. We do not collect, store, or use information that could be used to identify you personally. We also do not collect information about app usage or other technical information, except for statistical purposes using Google Analytics.</p>
                <br />
                <span>Storage duration</span>
                <p>The data collected by Google Analytics is stored according to Google Analytics' retention policies. These policies stipulate that the data is kept for a maximum period of 26 months, after which it is automatically deleted from Google Analytics servers. This means that data collected by Google Analytics through our app will not be held indefinitely and will be deleted after a 26-month period. It is worth noting that Google Analytics may, in some cases, retain the data for longer, in compliance with legal obligations, regulatory requirements, or for system security purposes. <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>
                <br />
                <span>Google Analytics </span>
                <p>This app uses Google Analytics, a web analytics service provided by Google Inc. ("Google"). Google Analytics uses "cookies", which are text files placed on your device, to help analyze the use of the app. The information generated by the cookie about the use of the app (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information to evaluate the use of the app, compiling reports on app activity for us, and providing other services related to app activity and internet usage. Google may also transfer this information to third parties if required by law or if such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. You can refuse the use of cookies by selecting the appropriate settings on your browser. Please note, however, that if you do this, you may not be able to use all the features of this app. By using this app, you agree to the processing of the data collected by Google in the manner and for the purposes described above. Personal Data processed: Usage Data; Trackers. Privacy Policy – Opt Out.
                    Here you will find more information on data usage by Google Inc.: <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a> (Data collected by Google partners) <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> (Settings about advertising displayed to you) <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a> (Use of cookies in ads)</p>
                    <br />
                <span>Sale of the full version and link to the book on Amazon </span>
                <p>This app offers the sale of the paid full version through the Google Play Store and has a redirecting link to the Amazon store for purchasing the numerology book related to the app. No personal data is collected during these transactions, which are handled directly by Google Play, Apple App Store, or Amazon, and are subject to the privacy policies of these services.
                    Google Play: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                    App Store: <a href="https://www.apple.com/legal/privacy/">https://www.apple.com/legal/privacy/</a>
                    Amazon: <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496">https://www.amazon.com/gp/help/customer/display.html?nodeId=468496</a></p>
                <br />

                <span>Payment methods</span>
                <p>
                    <span className='inner-text'>Google Play Store:</span> Payments on the Google Play Store are processed by Google and are subject to Google's Payment Policy. Google collects and processes your payment information, such as credit card number, billing address, and billing information, in accordance with applicable privacy laws. For more information on how Google handles payment information, please refer to Google's Payment Policy. <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.<br />
                    <span className='inner-text'>Apple App Store:</span> Payments on the Apple App Store are processed by Apple and are subject to the App Store's terms and conditions. Apple collects and processes your payment information, such as credit card number, billing address, and billing information, in accordance with applicable privacy laws. For more information on how Apple handles payment information, please see Apple's Privacy Policy. <a href="https://www.apple.com/legal/privacy/">https://www.apple.com/legal/privacy/</a>. <br />
                    <span className='inner-text'>Amazon:</span> Payments on Amazon are processed by Amazon and are subject to Amazon Payments' terms and conditions. Amazon collects and processes your payment information, such as credit card number, billing address, and billing information, in accordance with applicable privacy laws. For more information on how Amazon handles payment information, please see Amazon Payments' Privacy Policy. <a href="https://pay.amazon.com/us/help/201751600">https://pay.amazon.com/us/help/201751600</a>.</p>
                    <br />

                <span>Your rights </span>
                <p>You have the right to know what personal data has been collected, how it is used, and how long it is stored. You have the right to request access, correction, deletion, or restriction of the processing of your personal data. You also have the right to file a complaint with the competent data protection authority.</p>
                <br />
                <span>Contact</span>
                <p>If you have any questions or concerns about how your personal data is processed, or if you wish to exercise your data protection rights, please contact us at the following email address: at.comercial.business@gmail.com . If you contact us through the contact options offered, your data will be saved so that it can be used to process and respond to your request. After processing and responding to your request, your email address will be deleted to ensure the protection of your personal data. None of your data will be transmitted to third parties without your consent.</p>
                <br />
                <span>Changes and updates to the privacy policy</span>
                <p>This data protection statement is updated regularly. The most recent version is always available in this app. We ask that you inform yourself regularly about the content of our data protection statement. We will adapt the data protection statement as soon as changes in the data processing we carry out make it necessary. We will inform you as soon as the changes require your cooperation (e.g., consent) or other individual notification. If we provide addresses and contact information of companies and organizations in this data protection statement, please note that addresses may change over time, and we ask you to check the information before contacting us.</p>
            </div>
        </div>
    )
}

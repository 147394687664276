import React from 'react'
import amazon from "../img/amazon-logo.png"
import stamp from "../img/stamp.png"
import BurgerButton from './extra/BurgerButton'
import { useNavigate, Link } from 'react-router-dom'
import language from '../locales/language'
import { myContext } from '../context/ContextProvider'

export default function Header({ amazonLogo }) {
  const navigate = useNavigate();
  const { Language } = React.useContext(myContext);

  return (
    <div className='header-section'>
      <BurgerButton />
      {/* <CornerTab /> */}
      <div className='stamp'>
        <Link to="/">
          <img src={stamp} alt="stamp" />
        </Link>
      </div>
      {
        amazonLogo === "false" ? null
          :
          <div className='amazon-container'>
            {/* <div className='line'></div> */}
            {/* <div className='amazon'>
              <Link to={language[Language].amazon.paperback}>
                <img src={amazon} alt="amazon" />
              </Link>
            </div> */}
            {/* <div className='line'></div> */}
          </div>
      }
    </div>
  )
}

import React, { useContext } from 'react'
import language from '../locales/language'
import { myContext } from '../context/ContextProvider';

export default function Info() {
    const {Language} = useContext(myContext);
    return (
        <div className='info-section'>
            <h1 className='info-title'>{language[Language].info.title}</h1>
            <h2 className='info-description'>{language[Language].info.subtitle}</h2>
        </div>
    )
}
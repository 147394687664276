import React, { useContext } from 'react'
import language from '../locales/language'
import check from "../img/check.png"
import { myContext } from '../context/ContextProvider';
import ReactPlayer from 'react-player/youtube'

export default function Why() {
  const { Language } = useContext(myContext);
  return (
    <div className='why-section'>

      <div className='why-title'>
        <p>{language[Language].why.title}</p>
      </div>

      <div className='why-video'>
        {/* <iframe
          // width="100%"
          // height="auto"
          src={language[Language].why.video}
          // frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title='Video'
        ></iframe> */}
        <ReactPlayer  url={language[Language].why.video} controls
          width="100%"
          height="auto"/>
      </div>

      <div className='why-reasons'>
        <p className='title'>{language[Language].why.title} </p>
        <div className='why-check-container'>
          {Object.keys(language[Language].why.reasons).map((key, index) => (
            <div key={index} className='item-check'>
              <img src={check} alt="check" />
              <p key={key} className='why-check'>{language[Language].why.reasons[key]}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

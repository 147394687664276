import React, { useContext } from 'react';
import book from "../img/capa_en.png";
import bookpt from "../img/capa_pt.png";
import bookde from "../img/capa_de.png";
import stars from "../img/stars5.png";
import language from '../locales/language';
import { myContext } from '../context/ContextProvider';
import { Helmet } from 'react-helmet';

export default function BookSection() {
  const {Language} = useContext(myContext);
  return (
    <div className='book-section'>
      <Helmet>
        <title>{language[Language].metatag.booksection.title}</title>
        <meta></meta>
      </Helmet>


      <div className='testemunho'>
        <img src={stars} alt="stars" />
        <p>"{language[Language].bookSection.testimony[0].text}"</p>
        <p>{language[Language].bookSection.testimony[0].user}</p>
      </div>
      <div className='book'>
        <img src={Language === "en" ? book : Language === "pt" ? bookpt : bookde} alt="book" />
      </div>
      <div className='testemunho'>
        <img src={stars} alt="stars" />
        <p>"{language[Language].bookSection.testimony[1].text}"</p>
        <p>{language[Language].bookSection.testimony[1].user}</p>
      </div>
    </div>
  )
}

import React, { useContext } from 'react'
import { myContext } from '../context/ContextProvider'
import CircleDisplay from './calculadora/CircleDisplay'
import meaning from "../locales/meaning/meaning"
// import BurgerButton from './extra/BurgerButton'
import Footer from './Footer'
// import BackButton from './extra/BackButton'
import Header from './Header'
export default function Relatorio() {

    const {
        date,
        setDate,
        Name,
        setName,
        Origem,
        OrigemCarm,
        Destino,
        DestinoCarm,
        Alma,
        Personalidade,
        Nascimento,
        NumeroPoderoso,
        Pinaculos,
        PinaculoVigente,
        Carma,
        Desafios,
        AnoPessoal,
        MesesPessoal,
        DiasPessoal,
        calcularOrigem,
        calcularNumeroDestino,
        calcularPersonalidade,
        calcularCarma,
        ListaNomes,
        DataFormatada,
        Language,
    } = useContext(myContext)

    // console.log("carma Relatorio");
    // console.log(Carma);
    return (
        <div className='main-container'>
            <div className='grid-container'>
                {/* <BurgerButton /> */}
                {/* <BackButton path="calculator" /> */}
                <Header amazonLogo="false" />

                <div className='relatorio-title'>
                    <h1>{Name}</h1>
                    <p >{DataFormatada}</p>
                </div>

                <div className='div-circle-container' >
                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].destiny.title,
                            description: meaning[Language].destiny.description,
                            number: Destino,
                            nome: Name,
                            destinoCarm: DestinoCarm
                        }}
                    />
                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].origin.title,
                            description: meaning[Language].origin.description,
                            number: Origem,
                            nome: Name
                        }}
                    // style={{ marginTop: 60, marginRight: 20 }}
                    />
                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].heart.title,
                            description: meaning[Language].heart.description,
                            number: Alma,
                            nome: Name
                        }}
                    />


                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].personality.title,
                            description: meaning[Language].personality.description,
                            number: Personalidade,
                            nome: Name
                        }}
                    />
                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].day.title,
                            description: meaning[Language].day.description,
                            number: Nascimento,
                            nome: Name
                        }}
                    />

                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].powernumber.title,
                            description: meaning[Language].powernumber.description,
                            number: NumeroPoderoso,
                            nome: Name
                        }}
                    // style={{ marginTop: 70, marginRight: 30 }}
                    />

                    <CircleDisplay
                        // navigation={navigation}
                        route={{
                            title: meaning[Language].pinacle.title,
                            description: meaning[Language].pinacle.description,
                            number: PinaculoVigente,
                            pinaculos: Pinaculos,
                            nome: Name
                        }}
                    // style={{ marginRight: 20 }}
                    />
                    <CircleDisplay
                        route={{
                            title: meaning[Language].year.title,
                            description: meaning[Language].year.description,
                            number: AnoPessoal,
                            anoPessoal: AnoPessoal,
                            nome: Name
                        }} />
                    <CircleDisplay
                        route={{
                            title: meaning[Language].month.title,
                            description: meaning[Language].month.description,
                            month: MesesPessoal,
                            ball: false
                        }} />
                    <CircleDisplay
                        route={{
                            title: meaning[Language].days.title,
                            description: meaning[Language].days.description,
                            dias: DiasPessoal,
                            ball: false
                        }} />
                    {Carma !== null && (
                        <CircleDisplay
                            route={{
                                title: meaning[Language].karma.title,
                                description: meaning[Language].karma.description,
                                number: null,
                                carma: Carma,
                                nome: Name,
                                ball: false
                            }}
                        />
                    )}
                    <CircleDisplay
                        route={{
                            title: meaning[Language].chalenge.title,
                            description: meaning[Language].chalenge.description,
                            number: null,
                            desafios: Desafios,
                            nome: Name,
                            ball: false
                        }} />
                </div>

                <Footer />
            </div>
        </div>
    )
}

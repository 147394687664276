// eslint-disable-next-line import/no-anonymous-default-export
export default {
    modal:"Este site utiliza o Google Analytics para coletar dados de análise, a fim de melhorar a experiência do usuário. Essas informações são coletadas de forma anônima e incluem dados como localização geográfica, dispositivo utilizado, páginas visitadas e tempo gasto no site. Ao continuar navegando neste site, você concorda com a coleta e processamento desses dados pelo Google Analytics. Para obter mais informações sobre como o Google Analytics coleta e processa dados, consulte a nossa Política de Privacidade.",
    
        metatag: {
          main: {
            title: "Sucesso na Numerologia: Transforme Sua Vida para Crescimento e Prosperidade",
            description: "Descubra o poder da numerologia e como ela pode transformar sua vida. Explore nosso livro para insights profundos e orientações."
          },
          booksection: {
            title: "Sucesso na Numerologia: Transforme Sua Vida para Crescimento e Prosperidade",
            description: "Embarque em uma jornada transformadora com 'O PODER DA NUMEROLOGIA: DESVENDANDO OS SEGREDOS DO SUCESSO'. Este livro desbloqueia a sabedoria antiga da numerologia para revelar sucesso, propósito e prosperidade em sua vida. Inclui acesso exclusivo a um APLICATIVO GRATUITO para explorar seu potencial oculto, construir relações profundas, encontrar o amor duradouro e atrair abundância. Inicie hoje sua jornada de autodescoberta e crescimento pessoal."
          },
          calculator: {
            title: "Calculadora de Numerologia: Descubra Seus Números Pessoais",
            description: "Desbloqueie os segredos da sua numerologia pessoal com nossa Calculadora de Numerologia. Insira seu nome de nascimento e data para descobrir seus números centrais e embarcar em uma jornada de autodescoberta e insight sobre o propósito da sua vida, desafios e forças."
          }
        },
        aria: {
          burger: {
            description: "Botão do Menu Burger"
          }
        },
    amazon: {
        kindle: "https://www.amazon.com.br/dp/B0CCL8XFNT",
        paperback: "https://www.amazon.com.br/dp/B0CCL8XFNT",
        hardcover: "https://www.amazon.com.br/dp/B0CCL8XFNT"
    },
    header: {
        burger: "Idioma",
        calculadora: "Calculadora"
    },
    bookSection: {
        testimony: [
            {
                text: "Um livro extremamente prático e útil. Revela as informações mais fundamentais sobre o significado dos números em nosso dia a dia.",
                user: "Leitor",
            },
            {
                text: "Este livro de numerologia foi transformador! De forma prática e direta, ele me ajudou a compreender o significado dos números, proporcionando uma nova direção em minha vida. Recomendo para aqueles que desejam se autoconhecer e se compreender melhor.",
                user: "Leitor",
            },
        ]
    },
    info: {
        title: "O PODER DA NUMEROLOGIA: UM GUIA COMPROVADO PARA TRANSFORMAR SUA VIDA",
        subtitle: "Este livro não é apenas um guia, é uma ferramenta de transformação projetada para ajudá-lo a entender melhor a si mesmo e ao mundo ao seu redor."
    },
    step1: {
        step: "Passo 1: Obtenha sua cópia de O Poder da Numerologia",
        type: ["eBook", "Livro de Bolso", "Capa Dura"]
    },
    step2: {
        step: "Passo 2: Obtenha seu aplicativo gratuito na Play Store",
        appName: "Calculadora de Numerologia"
    },
    why: {
        title: "POR QUE A NUMEROLOGIA É A CHAVE PARA A Autocompreensão",
        reasons: {
            1: "Autoconsciência: A numerologia revela aspectos ocultos da personalidade e habilidades únicas.",
            2: "Identificação de padrões: A análise numerológica revela padrões comportamentais e emocionais para promover o crescimento pessoal.",
            3: "Orientação na tomada de decisões: A numerologia oferece insights valiosos para tomar decisões importantes.",
            4: "Compreensão de desafios e oportunidades: A análise numerológica revela desafios e oportunidades pessoais.",
            5: "Fortalecimento da autoconfiança: A numerologia revela pontos fortes e talentos únicos, fortalecendo a autoconfiança.",
            6: "Crescimento pessoal: A numerologia é uma ferramenta para buscar crescimento e melhorias pessoais.",
            7: "Melhoria nos relacionamentos: A compreensão numerológica melhora a comunicação e a harmonia nos relacionamentos.",
            8: "Empoderamento pessoal: A numerologia capacita cada pessoa a buscar seu próprio desenvolvimento e autenticidade."
        },
        video: "https://www.youtube.com/embed/v5sZKdW-XJ4"
    },
    terms: {
        title: "Privacidade e Termos de Uso",
        link: "https://docs.google.com/document/d/1M2WSu6F1f6t3FOq4JfSdwhRZpmebRQVG7fnXekHEM0w/edit?usp=drive_link"
    },
    calculator:{
        burger: {
            sobre: "Sobre",
            ajuda: {
                titulo: "Ajuda",
                sobre: "Sobre",
                faq: "FAQ",
                contato: "Contato", 
                termos: "Termos do aplicativo",
                appinfo: "App Info",
                politica: "https://docs.google.com/document/d/18AJiJ-U__OH_OwplLJNPL4BsoEtows-fyiD7y0Jv7bg/edit?usp=sharing",
            }
        },
        onboard: {
            pagina1: {
                titulo: "Bem-vindo!",
                subtitulo: "Aqui você irá encontrar tudo o que precisa para fazer o cálculo numerológico.",
            },
            pagina2: {
                titulo: "Passo a passo fácil",
                subtitulo: "Siga nossas instruções e descubra sua numerologia de maneira simples e descomplicada.",
            },
            pagina3: {
                titulo: "O Poder da Numerologia",
                subtitulo: "Este App foi construido para ser a calculadora do livro \"O Poder da Numerologia\". Recomendamos a leitura do livro antes de usar o App",
            },
            pagina4: {
                titulo: "Nosso Livro",
                subtitulo: "Adquira nosso livro e aprofunde seus conhecimentos sobre numerologia! Descubra como os números podem revelar aspectos importantes de sua vida e como utilizá-los em seu benefício.",
            },
        },
        botoes: {
            voltar: "Voltar"
        },
        main: {
            titulo: "Calculadora Numerológica",
            subtitulo: "Descubra seus números pessoais",
            lista: "Lista de Nomes",
            calcular: "Calcular",
            livro: "Livro de Interpretação"
        },
        lista: {
            lista: "Historico de Nomes",
            lista_description: "Historico de nomes consultados",
        },
        input: {
            erro: "Por favor, insira um nome válido sem caracteres especiais ou acentos.",
            inputNome: "Nome:",
            inputData: "Data de Nascimento:",
            botaoCalcular: "Calcular",
            textoTitulo: "Instruções de como fazer o cálculo de numerologia:",
            texto1: " 1. Insira seu nome completo de nascimento.",
            texto2: "2. Remova as preposições do nome, como \"de\", \"da\", \"do\", \"dos\" e \"das\".",
            texto3: "3. Não utilize acentuação. Substitua letras acentuadas por suas versões sem acento. Por exemplo, substitua \"ü\" por \"u\", \"ä\" por \"a\" e assim por diante.",
            texto4: "4. Insira a data de nascimento"
        },
        relatorioSimples: {
            karma: "Karma",
            desafios: "Desafios",
            ano: "Ano",
            relatorio: {
                text1: "Confira o livro para mais informações e a interpretação do número."
            }
        },
        meaning: {
            titulo: {
                relatorio: "Relatório",
            },
            pinaculos: {
                primeiro: "Primeiro Pináculo:",
                segundo: "Segundo Pináculo:",
                terceiro: "Terceiro Pináculo:",
                quarto: "Quarto Pináculo:",
                entre: "entre",
                e: "e",
                ate: "até",
                anos: "anos de idade.",
                dos: "dos",
                fim: "até o fim da vida."
            },
            carma: {
                faltando: "Numeros faltando:",
                excesso: "Numeros em excesso:",
            },
            desafios: {
                primeiro: "Primeiro:",
                segundo: "Segundo:",
                terceiro: "Terceiro:",
                quarto: "Quarto:"
            },
            anoPessoal: {
                anoPessoal: "Ano pessoal, após seu aniversario em"
            }
        },
        compreolivro: {
            titulo: "O Poder da Numerologia",
            texto: "Este livro apresenta os fundamentos da numerologia e como ela pode ser aplicada para melhorar sua vida em todas as áreas.",
            botao: "Comprar na Amazon",
        },
        compliance: {
            termsOfServiceTitle: "Terms of Service",
            termsOfServiceText: "By using this app, you agree to our terms of service.",
            privacyPolicyTitle: "Privacy Policy",
            privacyPolicyText: "We are committed to protecting your privacy. Read our privacy policy for more information."
        }
    }
}
import React, { useState } from 'react'
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import language from '../../locales/language'
import { myContext } from '../../context/ContextProvider';
import { Link } from 'react-router-dom';
import book from "../../img/livro-capa-3d-en.png"
import meaning from '../../locales/meaning/meaning';

export default function CircleDisplay({ route }) {
    const {
        ball,
        title,
        description,
        number,
        pinaculos,
        carma,
        desafios,
        anoPessoal,
        month,
        dias,
        destinoCarm
    } = route
    const [isOpen, setIsOpen] = useState(false);
    const { Language } = React.useContext(myContext);
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();


    return (
        <div className='circle-display' onClick={() => setIsOpen(!isOpen)}>
            <div className='ball-title-modal-container'>
                {/* <div className='ball-title-container'> */}
                {ball === false ? <div style={{ opacity: 0 }} className="ball" />
                    :
                    <div className='ball'>
                        <p>{number}</p>
                    </div>
                }
                <div className='title'>
                    <p>{title}</p>
                </div>
                {/* </div> */}
                <h3>
                    {isOpen ? <AiOutlineUp /> : <AiOutlineDown />}
                </h3>
            </div>

            <div className={`description-container ${isOpen ? 'open' : ''}`}>
                {isOpen &&
                    <div className='description-text-container'>
                        <p className="description">{description}</p>
                        {carma && (
                            <div className='carma-text-container text-description'>
                                <p>
                                    {language[Language].calculator.meaning.carma.excesso} {carma["numerosAusentes"].join(", ")}
                                </p>
                                <p>
                                    {language[Language].calculator.meaning.carma.faltando} {carma["numerosEmExcesso"].join(", ")}
                                </p>
                            </div>
                        )}
                        {desafios &&
                            <div className='desafios-text-container text-description'>
                                <p >{language[Language].calculator.meaning.desafios.primeiro} {desafios["primeiro"]} {language[Language].calculator.meaning.pinaculos.ate} 30 {language[Language].calculator.meaning.pinaculos.anos}</p>
                                <p >{language[Language].calculator.meaning.desafios.segundo} {desafios["segundo"]} {language[Language].calculator.meaning.pinaculos.entre} 31 {language[Language].calculator.meaning.pinaculos.e} 45 {language[Language].calculator.meaning.pinaculos.anos}</p>
                                <p >{language[Language].calculator.meaning.desafios.terceiro} {desafios["terceiro"]} {language[Language].calculator.meaning.pinaculos.entre} 46 {language[Language].calculator.meaning.pinaculos.e} 60 {language[Language].calculator.meaning.pinaculos.anos}</p>
                                <p >{language[Language].calculator.meaning.desafios.quarto} {desafios["quarto"]} {language[Language].calculator.meaning.pinaculos.dos} 61 {language[Language].calculator.meaning.pinaculos.fim}</p>
                            </div>
                        }
                        {anoPessoal &&
                            <div className="anopessoal-text-container text-description">
                                <p >{language[Language].calculator.meaning.anoPessoal.anoPessoal} {ano}: {anoPessoal}</p>
                            </div>
                        }
                        {pinaculos &&
                            <div className='pinaculos-text-container text-description'>
                                <p>
                                    {language[Language].calculator.meaning.pinaculos.primeiro} {pinaculos['Primeiro']['numero']} {language[Language].calculator.meaning.pinaculos.ate} {pinaculos["Primeiro"]["idade"]} {language[Language].calculator.meaning.pinaculos.anos}
                                </p>
                                <p >
                                    {language[Language].calculator.meaning.pinaculos.segundo} {pinaculos['Segundo']['numero']} {language[Language].calculator.meaning.pinaculos.entre} {pinaculos["Segundo"]["idadeInicio"]} {language[Language].calculator.meaning.pinaculos.e} {pinaculos["Segundo"]["idadeFim"]} {language[Language].calculator.meaning.pinaculos.anos}
                                </p>
                                <p >
                                    {language[Language].calculator.meaning.pinaculos.terceiro} {pinaculos['Terceiro']['numero']} {language[Language].calculator.meaning.pinaculos.entre} {pinaculos["Terceiro"]["idadeInicio"]} {language[Language].calculator.meaning.pinaculos.e} {pinaculos["Terceiro"]["idadeFim"]} {language[Language].calculator.meaning.pinaculos.anos}
                                </p>
                                <p >{language[Language].calculator.meaning.pinaculos.quarto} {pinaculos['Quarto']['numero']} {language[Language].calculator.meaning.pinaculos.dos} {pinaculos["Quarto"]["idadeInicio"]} {language[Language].calculator.meaning.pinaculos.fim}
                                </p>
                            </div>
                        }
                        {
                            month &&
                            <div className='tabela'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{meaning[Language].month.text.month}</th>
                                            <th>{meaning[Language].month.text.value}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(month).map(([mes, valor]) => (
                                            <tr key={mes}>
                                                <td>{mes}</td>
                                                <td>{valor}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }

                        {
                            dias &&
                            <div className='tabela'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{meaning[Language].days.text.day}</th>
                                            <th>{meaning[Language].days.text.value}</th>
                                            <th>{meaning[Language].days.text.day}</th>
                                            <th>{meaning[Language].days.text.value}</th>
                                            <th>{meaning[Language].days.text.day}</th>
                                            <th>{meaning[Language].days.text.value}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(dias).reduce((acc, [dia, valor], index) => {
                                            if (index % 3 === 0) {
                                                acc.push([{ dia, valor }]);
                                            } else {
                                                acc[acc.length - 1].push({ dia, valor });
                                            }
                                            return acc;
                                        }, []).map((grupo, index) => (
                                            <tr key={index}>
                                                {grupo.map((item, subIndex) => (
                                                    <React.Fragment key={subIndex}>
                                                        <td>{item.dia}</td>
                                                        <td>{item.valor}</td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div className='book-link-container'>
                            <p>
                                {language[Language].calculator.relatorioSimples.relatorio.text1}
                            </p>
                            <Link to={language[Language].amazon.paperback} >
                                <img src={book} alt="stars" />
                            </Link>
                        </div>

                    </div>
                }

            </div>

        </div>

    )
}
import React, { useContext } from 'react'
import language from '../locales/language'
import { myContext } from '../context/ContextProvider'
import { Link } from 'react-router-dom'

export default function Footer() {
    const { Language } = useContext(myContext)
    return (
        <div className='footer-container' >
            {/* <a href={language[Language].terms.link} target="_blank" rel="Terms and Privacy">
                <p>{language[Language].terms.title}</p>
            </a> */}

            <Link
                to={Language === 'en' ? '/policy' : Language === 'pt' ? '/policypt' : '/policyde'}
                target="_blank">
                <p>{language[Language].terms.title}</p>
            </Link>
        </div>
    )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    modal:"Diese Website verwendet Google Analytics, um Analyse-Daten zu sammeln und das Benutzererlebnis zu verbessern. Diese Informationen werden anonym gesammelt und umfassen Daten wie geografischen Standort, verwendete Geräte, besuchte Seiten und die auf der Website verbrachte Zeit. Durch die fortgesetzte Nutzung dieser Website stimmen Sie der Erhebung und Verarbeitung dieser Daten durch Google Analytics zu. Weitere Informationen darüber, wie Google Analytics Daten erhebt und verarbeitet, finden Sie in unserer Datenschutzerklärung.",
    metatag: {
        main: {
          title: "Erfolg in der Numerologie: Verwandeln Sie Ihr Leben für Wachstum und Wohlstand",
          description: "Entdecken Sie die Kraft der Numerologie und wie sie Ihr Leben transformieren kann. Erkunden Sie unser Buch für tiefgreifende Einblicke und Anleitungen."
        },
        booksection: {
          title: "Erfolg in der Numerologie: Verwandeln Sie Ihr Leben für Wachstum und Wohlstand",
          description: "Begeben Sie sich auf eine transformative Reise mit 'DIE MACHT DER NUMEROLOGIE: DIE GEHEIMNISSE DES ERFOLGS ENTHÜLLEN'. Dieses Buch erschließt altes Wissen der Numerologie, um Erfolg, Zweck und Wohlstand in Ihrem Leben zu offenbaren. Inklusive exklusivem Zugang zu einer KOSTENLOSEN APP, um Ihr verborgenes Potenzial zu erkunden, tiefe Beziehungen aufzubauen, dauerhafte Liebe zu finden und Wohlstand anzuziehen. Beginnen Sie heute Ihre Reise der Selbstentdeckung und persönlichen Entwicklung."
        },
        calculator: {
          title: "Numerologie-Rechner: Entdecken Sie Ihre Persönlichen Zahlen",
          description: "Enthüllen Sie die Geheimnisse Ihrer persönlichen Numerologie mit unserem Numerologie-Rechner. Geben Sie Ihren Geburtsnamen und das Geburtsdatum ein, um Ihre Kernzahlen zu entdecken und sich auf eine Reise der Selbstentdeckung und Einblicke in den Zweck Ihres Lebens, Herausforderungen und Stärken zu begeben."
        }
      },
      aria: {
        burger: {
          description: "Burger-Menü-Button"
        }
      },
    amazon: {
        kindle: "https://www.amazon.de/dp/B0CCZF2JTG",
        paperback: "https://www.amazon.de/dp/B0CCZF2JTG",
        hardcover: "https://www.amazon.de/dp/B0CCZF2JTG"
    },
    header: {
        burger: "Sprache",
        calculadora: "Rechner"
    },
    bookSection: {
        testimony: [
            {
                text: "Ein überaus praktisches und nützliches Buch. Es zeigt die grundlegendsten Informationen über die Bedeutung von Zahlen in unserem täglichen Leben.",
                user: "Leser",
            },
            {
                text: "Dieses numerologische Buch war transformativ! Auf praktische und unkomplizierte Weise half es mir, die Bedeutung von Zahlen zu verstehen und eine neue Richtung in meinem Leben zu bieten. Ich empfehle es nur denjenigen, die sich selbst entdecken und sich selbst besser verstehen möchten.",
                user: "Leser"
            },
        ]
    },
    info: {
        title: "DIE MACHT DER NUMEROLOGIE: EIN BEWÄHRTER RATGEBER, UM IHR LEBEN ZU VERÄNDERN",
        subtitle: "Dieses Buch ist nicht nur ein Leitfaden, sondern ein transformationales Werkzeug, das Ihnen dabei hilft, sich selbst und die Welt um Sie herum besser zu verstehen."
    },
    step1: {
        step: "Schritt 1: Holen Sie sich Ihre Ausgabe von Die Macht der Numerologie",
        type: ["eBook", "Taschenbuch", "Gebundenes Buch"]
    },
    step2: {
        step: "Schritt 2: Holen Sie sich Ihre kostenlose App im Play Store",
        appName: "Numerologie Rechner"
    },
    why: {
        title: "WARUM DIE NUMEROLOGIE DER SCHLÜSSEL ZUR SELBSTVERBESSERUNG IST",
        reasons: {
            1: "Selbstbewusstsein: Numerologie enthüllt verborgene Aspekte der Persönlichkeit und einzigartige Fähigkeiten.",
            2: "Mustererkennung: Numerologische Analysen zeigen Verhaltens- und emotionale Muster für persönliches Wachstum auf.",
            3: "Orientierungshilfe bei Entscheidungen: Numerologie bietet wertvolle Erkenntnisse für wichtige Entscheidungen.",
            4: "Verständnis von Herausforderungen und Chancen: Numerologische Analysen enthüllen persönliche Herausforderungen und Chancen.",
            5: "Stärkung des Selbstvertrauens: Numerologie enthüllt Stärken und einzigartige Talente, stärkt das Selbstvertrauen.",
            6: "Persönliches Wachstum: Numerologie ist ein Werkzeug zur Suche nach persönlichem Wachstum und Verbesserung.",
            7: "Verbesserung der Beziehungen: Numerologisches Verständnis verbessert die Kommunikation und Harmonie in Beziehungen.",
            8: "Persönliche Ermächtigung: Numerologie befähigt Einzelpersonen, ihre eigene Entwicklung und Authentizität anzustreben."
        },
        video: "https://www.youtube.com/embed/MRjn50iuuEU"
    },
    terms: {
        title: "Datenschutz und Nutzungsbedingungen",
        link: "https://docs.google.com/document/d/1M2WSu6F1f6t3FOq4JfSdwhRZpmebRQVG7fnXekHEM0w/edit?usp=drive_link"
    },
    calculator:{
        burger: {
            sobre: "Über uns",
            ajuda: {
                titulo: "Hilfe",
                sobre: "Über",
                faq: "FAQ",
                contato: "Email", 
                termos: "Bedingungen und Datenschutzbestimmungen",
                appinfo: "App-Information",
                politica: "https://docs.google.com/document/d/1ZqwjdWr46emy4WCl8hnNdYBHNJUR-YOc9LCHnryMX4I/edit",
            }
        },
        onboard: {
            pagina1: {
                titulo: "Herzlich Willkommen!",
                subtitulo: "Hier finden Sie alles, was Sie für die numerologische Berechnung benötigen.",
            },
            pagina2: {
                titulo: "Einfacher Schritt-für-Schritt-Prozess",
                subtitulo: "Folgen Sie unseren Anweisungen und entdecken Sie Ihre Numerologie auf einfache und unkomplizierte Weise.",
            },
            pagina3: {
                titulo: "Die Macht der Numerologie",
                subtitulo: "Diese App wurde entwickelt, um der Taschenrechner des Buches \"Die Macht der Numerologie\" zu sein. Wir empfehlen Ihnen, das Buch vor der Verwendung der App zu lesen.",
            },
            pagina4: {
                titulo: "Das Buch",
                subtitulo: "Erwerben Sie unser Buch und vertiefen Sie Ihr Wissen über Numerologie! Entdecken Sie, wie Zahlen wichtige Aspekte Ihres Lebens offenbaren können und wie Sie sie zu Ihrem Vorteil nutzen können.",
            },
        },
        botoes: {
            voltar: "Zurück"
        },
        main: {
            titulo: "Numerologie Rechner",
            subtitulo: "Entdecken Sie Ihre persönlichen Zahlen",
            lista: "Name Liste",
            calcular: "Berechnen",
            livro: "Interpretationsbuch"
        },
        lista: {
            lista: "Namenshistorie",
            lista_description: "Geschichte der konsultierten Namen",
        },
        input: {
            erro: "Bitte geben Sie einen gültigen Namen ohne Sonderzeichen oder Akzente ein.",
            inputNome: "Name:",
            inputData: "Geburtsdatum:",
            botaoCalcular: "Berechnen",
            textoTitulo: "Anleitung zur Berechnung der Numerologie:",
            texto1: "1. Geben Sie Ihren vollständigen Geburtsnamen ein.",
            texto2: "2. Entfernen Sie Präpositionen aus dem Namen wie \"von\", \"zu\".",
            texto3: "3. Verwenden Sie keine Akzente. Ersetzen Sie akzentuierte Buchstaben durch ihre nicht akzentuierten Versionen. Zum Beispiel, ersetzen Sie \"ü\" durch \"u\", \"ä\" durch \"a\" und so weiter.",
            texto4: "4. Geben Sie das Geburtsdatum ein."
        },
        relatorioSimples: {
            karma: "Karma",
            desafios: "Herausforderungen",
            ano: "Jahr",
            relatorio: {
                text1: "Schauen Sie in das Buch für weitere Informationen und die Interpretation der Nummer."
            }
        },
        meaning: {
            titulo: {
                relatorio: "Bericht"
            },
            pinaculos: {
                primeiro: "Erster Pinnacle:",
                segundo: "Zweiter Pinnacle:",
                terceiro: "Dritter Pinnacle:",
                quarto: "Vierter Pinnacle:",
                entre: "zwischen",
                e: "und",
                ate: "bis",
                anos: "Jahren.",
                dos: "der",
                fim: "bis zum Ende des Lebens."
            },
            carma: {
                faltando: "Fehlende Zahlen:",
                excesso: "Überschüssige Zahlen:"
            },
            desafios: {
                primeiro: "Erste:",
                segundo: "Zweite:",
                terceiro: "Dritte:",
                quarto: "Vierte:"
            },
            anoPessoal: {
                anoPessoal: "Persönliches Jahr, nach Ihrem Geburtstag im"
            }
        },
        compreolivro: {
            titulo: "Die Macht der Numerologie",
            texto: "Dieses Buch präsentiert die Grundlagen der Numerologie und wie sie in allen Bereichen Ihres Lebens angewendet werden kann, um es zu verbessern.",
            botao: "Bei Amazon kaufen",
        },
        compliance: {
            termsOfServiceTitle: "Nutzungsbedingungen",
            termsOfServiceText: "Durch die Nutzung dieser App erklären Sie sich mit unseren Nutzungsbedingungen einverstanden.",
            privacyPolicyTitle: "Datenschutzerklärung",
            privacyPolicyText: "Wir sind verpflichtet, Ihre Privatsphäre zu schützen. Lesen Sie unsere Datenschutzerklärung für weitere Informationen."
        }
    }
}
import React, { useState, useContext, useRef } from 'react';
import { myContext } from '../../context/ContextProvider';
import language from '../../locales/language';
import { useNavigate } from 'react-router-dom';

const BurgerButton = () => {
    const navigate = useNavigate();
    const { Language, setLanguage } = useContext(myContext);
    const menuRef = useRef(null);
    const [menuLanguage, setMenuLanguage] = useState(false);
    const [menu, setMenu] = useState(false);

    const toggleMenu = () => {
        setMenu(!menu);
        setMenuLanguage(false);
        // console.log("toggleMenu")
    };

    const changeLanguage = (option) => {
        setLanguage(option);
        // console.log("ChangeLanguage")
    };

    const handleMouseLeave = () => {
        setMenu(!menu);
        // setMenuLanguage(false);
        // console.log("HandleMouseLeave")
    };

    const handleLanguageMenu = () => {
        setMenu(false);
        setMenuLanguage(false);
        // console.log("handleLanguageMenu")
        
    };

    return (
        <div className='burger-container' >
            <button className={`burger-button ${menu ? 'open' : ''}`} onClick={toggleMenu}
            aria-label={language[Language].aria.burger.description} // Adiciona uma descrição acessível
            aria-expanded={menu} // Informa se o menu está expandido
        >
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </button>

            {menu && (
                <div className="menu" ref={menuRef} onMouseLeave={handleMouseLeave} >
                    <p onClick={() => setMenuLanguage(true)} >{language[Language].header.burger}</p>
                    <p onClick={() => navigate("/calculator")} >{language[Language].header.calculadora}</p>
                </div>
            )}

            {
                menuLanguage && (
                    <div className="menu" ref={menuRef} onMouseLeave={handleLanguageMenu} onClick={handleLanguageMenu} >
                    <p>{language[Language].header.burger}</p>
                    <button onClick={() => changeLanguage('pt')}>Português</button>
                    <button onClick={() => changeLanguage('en')}>English</button>
                    <button onClick={() => changeLanguage('de')}>Deutsch</button>
                </div>
                )
            }

        </div>
    );
};

export default BurgerButton;

import React, { useContext } from 'react'
import Header from './Header'
import BookSection from './BookSection'
import Info from './Info'
import Step1 from './Step1'
import Step2 from './Step2'
import Why from './Why'
import Book from './Book'
import Decoration from './extra/Decoration'
import Footer from './Footer'
import Modal from './extra/Modal'
import { Helmet } from 'react-helmet'
import language from '../locales/language'
import { myContext } from '../context/ContextProvider'

export default function Main() {
  const { Language } = useContext(myContext);
  return (
    <div className='main-container'>
      <Helmet>
        <title>{language[Language].metatag.main.title}</title>
        <meta name="description" content={language[Language].metatag.main.description} />
      </Helmet>
      <Decoration />
      <Modal/>
      <div className='grid-container'>
        <Header />
        <BookSection />
        <Info />
        <Step1 />
        <Step2 />
        <Why />
        <Book />
        <Step1 />
        <Step2 />
        <Footer/>
      </div>
    </div>
  )
}

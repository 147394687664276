import React, { useContext, useState } from 'react';
import language from '../../locales/language';
import { myContext } from '../../context/ContextProvider';
import { Link } from 'react-router-dom';

const Modal = () => {
    const { Language } = useContext(myContext);
    const [isOpen, setIsOpen] = useState(localStorage.getItem("modalClosed") === null);
    const [isChecked, setIsChecked] = useState(true);
    const [notChecked, setNotChecked] = useState(false);

    const handleClose = () => {
        if (isChecked === true) {
            localStorage.setItem("modalClosed", "true");
            setIsOpen(false);
        }else if (notChecked === true){
            setIsOpen(false);
        }
    };

    const handleInput = (checkboxId) => {
        if(checkboxId === "accept"){
            setIsChecked(!isChecked);
            setNotChecked(false);
        } else if(checkboxId === "reject"){
            setNotChecked(!notChecked);
            setIsChecked(false);
        }
    }

    React.useEffect(() => {
        // Só executa a função quando o componente é montado
        if (localStorage.getItem("modalClosed")) {
            setIsOpen(false);
        }
    }, []);

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h1>Cookies & Privacy</h1>
                        <div className='text-container'>
                            <p className='modal-text'>
                                {language[Language].modal}
                            </p>
                            <Link to={Language === 'en' ? '/policy' : Language === 'pt' ? '/policypt' : '/policyde'}
                                target="_blank">
                                <p>{language[Language].terms.title}</p>
                            </Link>

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="accept"
                                    checked={isChecked}
                                    onChange={() => handleInput("accept")}
                                />
                                <label className="form-check-label" htmlFor="accept">
                                    <p>
                                        <strong>Accept</strong>
                                    </p>
                                </label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="reject"
                                    checked={notChecked}
                                    onChange={() => handleInput("reject")}
                                />
                                <label className="form-check-label" htmlFor="reject">
                                    <p>
                                        <strong>Reject</strong>
                                    </p>
                                </label>
                                <button onClick={handleClose}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;

import React from 'react'

export default function PolicyPT() {
    return (
        <div className='policy-container'>
            <div className='policy-text-section'>

                <h1>Política de Privacidade</h1>

                <span>Informações gerais</span>
                <p>Este aplicativo de numerologia é oferecido pela A&T Comercial. Somos responsáveis pelo processamento de dados pessoais que ocorre ao usar este aplicativo. Nós levamos a proteção de seus dados pessoais muito a sério e seguimos rigorosamente as leis de proteção de dados aplicáveis.
                </p>
                <br />
                <span>Coleta de dados pessoais</span>
                <p>Este aplicativo não coleta dados pessoais. Nós não coletamos, armazenamos ou usamos informações que possam ser usadas para identificar você pessoalmente. Também não coletamos informações sobre o uso do aplicativo ou outras informações técnicas, exceto para fins estatísticos usando o Google Analytics.
                </p>
                <br />
                <span>Duração de armazenamento</span>
                <p>Os dados coletados pelo Google Analytics são armazenados de acordo com as políticas de retenção do Google Analytics. Essas políticas estipulam que os dados são mantidos por um período máximo de 26 meses, após o qual são automaticamente excluídos dos servidores do Google Analytics. Isso significa que os dados coletados pelo Google Analytics através do nosso aplicativo não serão mantidos indefinidamente e serão excluídos após um período de 26 meses. Vale ressaltar que o Google Analytics pode, em alguns casos, reter os dados por mais tempo, em conformidade com obrigações legais, exigências regulatórias ou para fins de segurança do sistema.
                    <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></p>
                <br />
                <span>Google Analytics </span>
                <p>Este aplicativo usa o Google Analytics, um serviço de análise da web fornecido pela Google Inc. ("Google"). O Google Analytics usa "cookies", que são arquivos de texto colocados no seu dispositivo, para ajudar a analisar o uso do aplicativo. As informações geradas pelo cookie sobre o uso do aplicativo (incluindo o seu endereço IP) serão transmitidas e armazenadas pelo Google em servidores nos Estados Unidos. O Google usará essas informações para avaliar o uso do aplicativo, compilando relatórios sobre a atividade do aplicativo para nós e fornecendo outros serviços relacionados à atividade do aplicativo e ao uso da internet. O Google também pode transferir essas informações para terceiros, se exigido por lei ou se esses terceiros processarem as informações em nome do Google. O Google não associará seu endereço IP a nenhum outro dado mantido pelo Google. Você pode recusar o uso de cookies selecionando as configurações apropriadas no seu navegador. Observe, no entanto, que, se você fizer isso, talvez não consiga usar todas as funcionalidades deste aplicativo. Ao usar este aplicativo, você concorda com o processamento dos dados coletados pelo Google da maneira e para os fins descritos acima. Dados Pessoais processados: Dados de uso; Rastreadores. Política de Privacidade – Opt Out.
                    Aqui você encontrará mais informações sobre o uso de dados pela Google Inc.:
                    <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a> (Dados coletados pelos parceiros do Google)  <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a> (Configurações sobre publicidade exibida para você) <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a> (Uso de cookies em anúncios)</p>
                <br />
                <span>Venda da versão completa e link para o livro na Amazon</span>
                <p>Este aplicativo oferece a venda da versão completa paga através da loja do Google Play e tem um link de direcionamento para a loja da Amazon para a compra do livro de numerologia relacionado ao aplicativo. Nenhum dado pessoal é coletado durante essas transações, que são tratadas diretamente pelo Google Play, Apple App Store ou Amazon, e estão sujeitas às políticas de privacidade desses serviços.

                    Google Play: <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
                    App Store: <a href="https://www.apple.com/legal/privacy/">https://www.apple.com/legal/privacy/</a>
                    Amazon: <a href="https://www.amazon.com/gp/help/customer/display.html?nodeId=468496">https://www.amazon.com/gp/help/customer/display.html?nodeId=468496</a></p>
                <br />

                <span>Meios de pagamento</span>
                <p>
                    <span className='inner-text'>Google Play Store:</span> Os pagamentos na Google Play Store são processados pelo Google e estão sujeitos à Política de Pagamentos do Google. O Google coleta e processa suas informações de pagamento, como o número do cartão de crédito, endereço de cobrança e informações de faturamento, em conformidade com as leis de privacidade aplicáveis. Para mais informações sobre como o Google trata as informações de pagamento, consulte a Política de Pagamentos do Google.
                    <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.<br />
                    <span className='inner-text'>Apple App Store:</span> Os pagamentos na Apple App Store são processados pela Apple e estão sujeitos aos termos e condições da App Store. A Apple coleta e processa suas informações de pagamento, como o número do cartão de crédito, endereço de cobrança e informações de faturamento, em conformidade com as leis de privacidade aplicáveis. Para mais informações sobre como a Apple trata as informações de pagamento, consulte a Política de Privacidade da Apple.  <a href="https://www.apple.com/legal/privacy/">https://www.apple.com/legal/privacy/</a>. <br />
                    <span className='inner-text'>Amazon:</span> Os pagamentos na Amazon são processados pela Amazon e estão sujeitos aos termos e condições da Amazon Payments. A Amazon coleta e processa suas informações de pagamento, como o número do cartão de crédito, endereço de cobrança e informações de faturamento, em conformidade com as leis de privacidade aplicáveis. Para mais informações sobre como a Amazon trata as informações de pagamento, consulte a Política de Privacidade da Amazon Payments. <a href="https://pay.amazon.com/us/help/201751600">https://pay.amazon.com/us/help/201751600</a>.</p>
                <br />

                <span>Seus direitos</span>
                <p>Você tem o direito de saber quais dados pessoais foram coletados, como eles são usados e por quanto tempo são armazenados. Você tem o direito de solicitar acesso, correção, exclusão ou restrição do processamento de seus dados pessoais. Você também tem o direito de apresentar uma queixa à autoridade de proteção de dados competente.
                </p>
                <br />
                <span>Contato</span>
                <p>Se você tiver alguma dúvida ou preocupação sobre como seus dados pessoais são processados, ou se desejar exercer seus direitos de proteção de dados, entre em contato conosco através do seguinte endereço de e-mail: at.comercial.business@gmail.com .
                    Se você entrar em contato conosco através das opções de contato oferecidas, seus dados serão salvos para que possam ser usados para processar e responder à sua solicitação. Após o processamento e a resposta da sua solicitação, o seu endereço de e-mail será excluído para garantir a proteção dos seus dados pessoais. Nenhum dos seus dados será transmitido a terceiros sem o seu consentimento.

                </p>
                <br />
                <span>Alteração e atualização da política de privacidade:</span>
                <p>Esta declaração de proteção de dados é atualizada regularmente. A versão mais recente está sempre disponível neste aplicativo.
                    Pedimos que se informe regularmente sobre o conteúdo da nossa declaração de proteção de dados. Adaptaremos a declaração de proteção de dados assim que as alterações no processamento de dados que realizarmos o tornarem necessários. Informaremos você assim que as alterações exigirem sua cooperação (por exemplo, consentimento) ou outra notificação individual. Se nós fornecermos endereços e informações de contato de empresas e organizações nesta declarada de proteção de dados, observe que os endereços podem mudar com o tempo e solicitamos que você verifique as informações antes de entrar em contato conosco.</p>

            </div>
        </div>
    )
}

import React, { useContext } from 'react'
import play from "../img/google-play-badge.png";
import play_de from "../img/google-play-badge-de.png";
import play_pt from "../img/google-play-badge-pt.png";
import qr from "../img/qr-code.png"
import siteQR from "../img/site-qr.png"
import { myContext } from '../context/ContextProvider';
import language from '../locales/language';
import { Link } from 'react-router-dom';

export default function Step2() {
  const { Language } = useContext(myContext);
  return (
    <div className='step2-section'>

      <p>{language[Language].step2.step} </p>

      <div className='step2-qr-container'>

        <div className='qr-container'>
          <a href="https://play.google.com/store/apps/details?id=com.thepowerofnumerology">
            <img className='play-store' src={Language === "de" ? play_de : Language === "pt" ? play_pt : play} alt="play store" />
          </a>
          <img className='qr-code' src={qr} alt="qr-code" />
          <a href="https://play.google.com/store/apps/details?id=com.thepowerofnumerology">
            <p>{language[Language].step2.appName} </p>
          </a>
        </div>

        <div className='qr-container'>
          <a href="https://play.google.com/store/apps/details?id=com.thepowerofnumerology">
            <p>Website</p>
          </a>
          <Link rel="stylesheet" to="/calculator" >
            <img className='qr-code' src={siteQR} alt="qr-code" />
          </Link>
          <a href="https://play.google.com/store/apps/details?id=com.thepowerofnumerology">
            <p>{language[Language].step2.appName}</p>
          </a>
        </div>

      </div>
    </div>
  )
}

import React, { useContext } from 'react'
// import book from "../img/livro-capa-3d-en.png"
import book from "../img/capa_en.png";
import bookpt from "../img/capa_pt.png";
import bookde from "../img/capa_de.png";
import { myContext } from '../context/ContextProvider';

export default function Book() {
    const {Language} = useContext(myContext);
    return (
        <div className='book-alone'>
            <img src={Language === "en" ? book : Language === "pt" ? bookpt : bookde} alt="book" />
        </div>
    )
}

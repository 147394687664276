import React, { useContext, useState } from 'react'
import Footer from './Footer'
import Modal from './extra/Modal'
import language from '../locales/language'
import { myContext } from '../context/ContextProvider'
import { useNavigate } from 'react-router-dom'
import BurgerButton from './extra/BurgerButton'
import BackButton from './extra/BackButton'
import Header from './Header'
import ModalError from './extra/ModalError'
import { Helmet } from 'react-helmet'

export default function Calculadora() {
    const navigate = useNavigate();
    const {
        Language,
        error,
        setError,
        Name,
        setName,
        date,
        setDate,
        calcularOrigem,
        calcularNumeroDestino,
        calcularPersonalidade,
        calcularCarma,
        calcularNumeroVogais,
        setListaNomes,
        ListaNomes,
        setDataFormatada,
    } = useContext(myContext);

    const [showContainerText, setShowContainerText] = useState(true);
    const [showDatePicker, setShowDatePicker] = useState(false);
    

    const handleNameChange = (value) => {
        // const regex = /^[a-zA-Z ]*$/; // regex para permitir apenas letras e espaços
        const regex = /^[a-zA-Zß ]*$/;
        if (regex.test(value)) {
            setName(value.toUpperCase());
            setError('');
            // console.log(value)
        } else {
            setError(`${language[Language].calculator.input.erro}`);
            // console.log(error)
        }
    };

    const saveListaNomes = async (lista) => {
        try {
            const jsonLista = JSON.stringify(lista);
            await localStorage.setItem('@listaNomes', jsonLista);
        } catch (e) {
            // console.log('Erro ao salvar lista de nomes: ', e);
        }
    }

    const handleDateChange = (event, selectedDate) => {
        // const currentDate = selectedDate || date;
        setDate(event);
        // console.log(event);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // console.log(date)
        const dataRecebida = new Date(date).toISOString();
        const dataFormatada = dataRecebida.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$3-$2-$1');
        // console.log(dataFormatada);
        setDataFormatada(dataFormatada);

        setListaNomes((oldState) => {
            const state = [
                ...oldState,
                {
                    name: Name,
                    date: dataFormatada
                }
            ];
            saveListaNomes(state);
            return state;
        });

        await calcularOrigem(dataFormatada);
        await calcularNumeroDestino(Name.toUpperCase());
        await calcularNumeroVogais(Name.toUpperCase());
        await calcularPersonalidade(Name.toUpperCase());
        await calcularCarma(Name.toUpperCase());

        navigate("/calculator/relatorio");
    }

    return (
        <div className='main-container'>
            <Helmet>
                <title>{language[Language].metatag.calculator.title}</title>
                <meta name="description" content={language[Language].metatag.calculator.description} />
            </Helmet>
            {/* <Decoration /> */}
            <Modal />
                        <div className='grid-container'>
                
                <Header amazonLogo="false" />
                {/* <BackButton path=""/> */}
                
                <div className='calculator-titles'>
                    <p>
                        {language[Language].calculator.main.titulo}
                    </p>
                    <p>
                        {language[Language].calculator.main.subtitulo}
                    </p>
                </div>
                
                <div className='calculator-instructions'>
                    <h2> {language[Language].calculator.input.textoTitulo}</h2>
                    <p> {language[Language].calculator.input.texto1}</p>
                    <p> {language[Language].calculator.input.texto2}</p>
                    <p> {language[Language].calculator.input.texto3}</p>
                    <p> {language[Language].calculator.input.texto4}</p>
                </div>

                <form className='calculator-form' onSubmit={handleFormSubmit}>
                    
                    <div className='calculator-name-input'>
                        <label htmlFor="nomeInput" >
                        {language[Language].calculator.input.inputNome}
                        </label>
                        <input
                            id="nomeInput"
                            type="text"
                            value={Name}
                            onChange={(event) => handleNameChange(event.target.value)}
                            aria-label="Nome"
                        />
                        {
                            error === "" ? null :
                            <ModalError message={error} />
                        }
                    </div>
                    
                    <div className='calculator-date-input'>
                        <label htmlFor="dateInput" >
                            {language[Language].calculator.input.inputData}
                        </label>
                        <input
                            id="dateInput"
                            type="date"
                            value={date}
                            onChange={(event) => handleDateChange(event.target.value)}
                            aria-label="Data de Nascimento"
                        />
                    </div >
                    
                    <button type="submit">{language[Language].calculator.main.calcular}</button>
                
                </form>
                <Footer />
            </div>
        </div>
    )
}

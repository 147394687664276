import './App.scss';
import Calculadora from './components/Calculadora';
import Main from './components/Main';
import Policy from './components/Policy';
import PolicyDE from './components/PolicyDE';
import PolicyPT from './components/PolicyPT';
import Relatorio from './components/Relatorio';
import ContextProvider from './context/ContextProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <div>
      <ContextProvider>
        <Router>
          <Routes>
          <Route path='/' element={<Main />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/policypt' element={<PolicyPT />} />
            <Route path='/policyde' element={<PolicyDE />} />
            <Route path='/calculator' element={<Calculadora/>}/>
            <Route path='/calculator/relatorio' element={<Relatorio/>}/>
          </Routes>
        </Router>
      </ContextProvider>
    </div>
  );
}

export default App;
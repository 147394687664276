// eslint-disable-next-line import/no-anonymous-default-export
export default {

    origin: {
        title: 'Caminho de Origem',
        description: 'Este número mostra a sua missão e propósito de vida. Ele ajuda a entender suas habilidades inatas e desafios, bem como a direção geral que sua vida deve tomar.',
    },
    
    day: {
        title: 'Dia de Nascimento',
        description: 'Isso reflete as qualidades e habilidades que trazemos desde o nascimento e pode ser usado para entender nossos talentos, pontos fortes e desafios.',
    },
    
    destiny: {
        title: 'Destino',
        description: 'Representa o seu potencial e as habilidades que você deve desenvolver ao longo da sua vida.',
    },
    
    heart: {
        title: 'Desejo da Alma',
        description: 'Este número revela suas motivações internas e desejos verdadeiros. Ajuda a entender o que realmente nutre sua alma e o que você quer alcançar em um nível mais profundo.',
    },
    
    personality: {
        title: 'Personalidade',
        description: 'Representa a imagem que você apresenta ao mundo e como os outros o veem. Este número ajuda a entender como você pode se apresentar de forma mais autêntica e atraente.',
    },
    
    powernumber: {
        title: 'Número Poderoso',
        description: 'Representa o coeficiente de energia da pessoa e influencia significativamente tanto o caminho de origem quanto o destino, atuando como um equilíbrio ao longo da vida.',
    },
    
    vowel: {
        title: 'Primeira Vogal',
        description: 'A primeira vogal mostra seus impulsos emocionais, atitudes e como você reage ou reagirá, ou seja, como se espera que você pense e aja.',
    },
    
    pinacle: {
        title: 'Pináculo',
        description: 'Esses ciclos representam diferentes fases de nossas vidas, cada uma com suas próprias lições, desafios e oportunidades.',
    },
    
    karma: {
        title: 'Lições Cármicas',
        description: 'Ajuda a identificar padrões kármicos e entender quais lições precisamos aprender para equilibrar nosso karma e evoluir espiritualmente.',
    },
    
    chalenge: {
        title: 'Desafios',
        description: 'Obstáculos ou lições que precisamos superar ao longo de nossas vidas.',
    },
    
    year: {
        title: 'Ano Pessoal',
        description: 'A energia predominante no ano.',
    }    ,
    month: {
        title: "Meses Pessoais",
        description: "O numero predominante para cada mes",
        text: {
            month: "Mes",
            value: "Valor"
        }
    },
    days:{
        title: "Dias pessoais",
        description: "A energia predominante para cada dia do mes",
        text: {
            day: "Dia",
            value: "Valor"
        }
    }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    modal:"This site uses Google Analytics to collect analytical data in order to improve the user experience. This information is collected anonymously and includes data such as geographical location, device used, pages visited, and time spent on the site. By continuing to browse this site, you agree to the collection and processing of this data by Google Analytics. For more information on how Google Analytics collects and processes data, please refer to our Privacy Policy.",
    metatag:{
        main: {
            title: "Numerology Success: Transform Your Life for Growth and Prosperity",
            description: "Discover the power of numerology and how it can transform your life. Explore our book for in-depth insights and guidance.",
        },
        booksection:{
            title: "Numerology Success: Transform Your Life for Growth and Prosperity",
            description:"Embark on a transformative journey with 'THE POWER OF NUMEROLOGY: UNCOVERING THE SECRETS OF SUCCESS'. This book unlocks ancient numerology wisdom to reveal success, purpose, and prosperity in your life. Includes exclusive access to a FREE APP for exploring your hidden potential, building deep relationships, finding lasting love, and attracting abundance. Start your journey of self-discovery and personal growth today.",
        },
        calculator:{
            title: "Numerology Calculator: Discover Your Personal Numbers",
            description:"Unlock the secrets of your personal numerology with our Numerology Calculator. Enter your birth name and date to discover your core numbers and embark on a journey of self-discovery and insight into your life's purpose, challenges, and strengths.",
        },
    },
    aria:{
        burger: {
            description: "Burger Menu Button"
        }
    },
    amazon: {
        kindle:    "https://www.aettales.com/#/collections/the-power-of-numerology?category=esoteric",
        paperback: "https://www.aettales.com/#/collections/the-power-of-numerology?category=esoteric",
        hardcover: "https://www.aettales.com/#/collections/the-power-of-numerology?category=esoteric"
    },
    header: {
        burger: "Language",
        calculadora: "Calculator"
    },
    bookSection: {
        testimony: [
            {
                text: "A supremely practical and useful book. It reveals the most fundamental information about the meaning of numbers in our daily lives.",
                user: "Reader",
            },
            {
                text: "This numerology book was transformative! In a practical and straightforward way, it helped me understand the meaning of numbers and provide a new direction in my life. I recommend it only to those who want to discover themselves and understand themselves better.",
                user: "Reader",
            },
        ]
    },
    info: {
        title: "THE POWER OF NUMEROLOGY: AN PROVEN GUIDE TO TRANSFORM YOUR LIFE",
        subtitle: "This book is not just a guide, it is a transformational tool designed to help you better understand yourself and the world around you."
    },
    step1: {
        step: "Step 1: Get Your Copy of The Power of Numerology",
        type: ["eBook", "Paperback", "Hardcover"]
    },
    step2: {
        step: "Step 2: Get Your free App at Play Store",
        appName: "Numerology Calculator"
    },
    why: {
        title: "WHY NUMEROLOGY ARE THE KEY OF SELF-IMPROVEMENT",
        reasons: {
            1: "Self-awareness: Numerology reveals hidden aspects of personality and unique abilities.",
            2: "Pattern identification: Numerological analysis reveals behavioral and emotional patterns for personal growth.",
            3: "Guidance in decision-making: Numerology offers valuable insights for making important decisions.",
            4: "Understanding challenges and opportunities: Numerological analysis reveals personal challenges and opportunities.",
            5: "Boosting self-confidence: Numerology reveals strengths and unique talents, strengthening self-confidence.",
            6: "Personal growth: Numerology is a tool for seeking personal growth and improvement.",
            7: "Improvement in relationships: Numerological understanding enhances communication and harmony in relationships.",
            8: "Personal empowerment: Numerology empowers individuals to pursue their own development and authenticity."
        },
        video: "https://www.youtube.com/embed/LQP-eUeHDuw"
    },
    terms: {
        title: "Privacy & Terms of Use",
        link: "https://docs.google.com/document/d/1M2WSu6F1f6t3FOq4JfSdwhRZpmebRQVG7fnXekHEM0w/edit?usp=drive_link"
    },
    calculator: {
        burger: {
            sobre: "About us",
            ajuda: {
                titulo: "Help",
                sobre: "Sobre",
                faq: "FAQ",
                contato: "Contact", 
                termos: "Terms and Privacy Policy",
                appinfo: "App Info",
                politica: "https://docs.google.com/document/d/1M2WSu6F1f6t3FOq4JfSdwhRZpmebRQVG7fnXekHEM0w/edit?usp=sharing",
            }
        },
        onboard: {
            pagina1: {
                titulo: "Welcome!",
                subtitulo: "Here you will find everything you need for numerological calculation.",
            },
            pagina2: {
                titulo: "Easy step-by-step process",
                subtitulo: "Follow our instructions and discover your numerology in an easy and uncomplicated way.",
            },
            pagina3: {
                titulo: "The Power of Numerology",
                subtitulo: "This app was designed to be the calculator of the book \"The Power of Numerology\". We recommend you to read the book before using the app.",
            },
            pagina4: {
                titulo: "The Book",
                subtitulo: "Purchase our book and deepen your knowledge about numerology! Discover how numbers can reveal important aspects of your life and how you can use them to your advantage.",
            },
        },
        botoes: {
            voltar: "Back"
        },
        main: {
            titulo: "Numerology Calculator",
            subtitulo: "Discover your personal numbers",
            lista: "Name List",
            calcular: "Calculate",
            livro: "Interpretation Book"
        },
        lista: {
            lista: "Name History",
            lista_description: "History of consulted names",
        },
        input: {
            erro: "Please enter a valid name without special characters or accents.",
            inputNome: "Name:",
            inputData: "Date of birth:",
            botaoCalcular: "Calculate",
            textoTitulo: "Instructions for calculating numerology:",
            texto1: "1. Enter your full birth name.",
            texto2: "2. Remove prepositions from the name such as \"of\".",
            texto3: "3. Do not use accents. Replace accented letters with their unaccented versions. For example, replace \"ü\" with \"u\", \"ä\" with \"a\" and so on.",
            texto4: "4. Enter the date of birth."
        },
        relatorioSimples: {
            karma: "Karma",
            desafios: "Challenges",
            ano: "Year",
            relatorio: {
                text1: "Check out the book for more information and interpretation of the number."
            }
        },
        meaning: {
            titulo: {
                relatorio: "Report"
            },
            pinaculos: {
                primeiro: "First pinnacle:",
                segundo: "Second pinnacle:",
                terceiro: "Third pinnacle:",
                quarto: "Fourth pinnacle:",
                entre: "between",
                e: "and",
                ate: "to",
                anos: "years.",
                dos: "of",
                fim: "to the end of life."
            },
            carma: {
                faltando: "Missing numbers:",
                excesso: "Excess numbers:"
            },
            desafios: {
                primeiro: "First:",
                segundo: "Second:",
                terceiro: "Third:",
                quarto: "Fourth:"
            },
            anoPessoal: {
                anoPessoal: "Personal year, after your birthday in"
            }
        },
        compreolivro: {
            titulo: "The Power of Numerology",
            texto: "This book presents the basics of numerology and how it can be applied in all areas of your life to improve it.",
            botao: "Buy on Amazon",
        },
        compliance: {
            termsOfServiceTitle: "Terms of Service",
            termsOfServiceText: "By using this app, you agree to our Terms of Service.",
            privacyPolicyTitle: "Privacy Policy",
            privacyPolicyText: "We are committed to protecting your privacy. Please read our Privacy Policy for more information."
        }
    }
}